<template>
	<div class="notifications">
		<vue-headful
			:title="[$t('menu.notifications')] + '- UNDERMRKT'"
			description="Description from vue-headful"
		/>
		<div class="container">
			<h1>
				<div class="icon">
					<font-awesome-icon :icon="['fa', 'history']" />
				</div>
				{{ $t("menu.history") }}
			</h1>
			<div class="row">
				<div class="content">
					<div class="card">
                                                {{api_user}}
                                        </div>
				</div>
				<!-- /content -->
				<sidebar>
					<template v-slot:title>{{
						$t("menu.notifications")
					}}</template>
					<template v-slot:text>{{
						$t("notifications.description")
					}}</template>
				</sidebar>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import sidebar from "@/components/Sidebar.vue";
export default {
	name: "Notificatiobs",
	data() {
		return {
                        api_user:'',
                };
	},
	components: {
		sidebar,
	},
	computed: {
		// map `this.user` to `this.$store.getters.user`
		...mapGetters({
			user: "user",
			plans: "plans",
			userSellers: "userSellers",
			userKeywords: "userKeywords",
		}),
	},
	methods: {
		getUserApi: function() {
			var obj = {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
                                        "X-UNDERMRKT-API-KEY":"bf904830a053f48181ae8dd960005b89"
				},
			};
			fetch("https://api.classic-code.pro/user/6908079", obj)
				.then((res)=> {
					return res.json();
				})
				.then((resJson) =>{
					// eslint-disable-next-line no-console
                                        this.api_user = resJson;
				});
		},
	},
	mounted() {
                this.getUserApi();
        },
};
</script>
<style lang="scss" scoped>
.icon {
	background-color: #db3741 !important;
}
.disable-insturction {
	margin-top: 10px;
	padding: 6px 8px;
	background-color: rgba(#3faff1, 0.2);
	border-radius: 6px;
	margin-bottom: 20px;
	color: #136ca0;
}
.notifications {
	&__item {
		border-bottom: 1px solid #e7e7e7;
		min-height: 100px;
		padding-top: 30px;

		.status {
			display: flex;
			justify-content: space-between;

			.info {
				.name {
					color: #606060;
					font-size: 20px;
					font-weight: 600;
				}

				.instructions {
					margin-top: 10px;
					padding: 6px 8px;
					background-color: rgba(#3faff1, 0.2);
					border-radius: 6px;
					margin-bottom: 20px;
					color: #136ca0;

					a {
						color: #136ca0;
					}

					&.viber {
						background-color: rgba(101, 91, 172, 0.15);
						color: #655bac;

						a {
							color: #655bac;
						}
					}

					ul {
						padding: 0px;
						margin: 0px;
						list-style: none;

						li {
							font-size: 13px;

							margin-bottom: 5px;

							.user-id {
								cursor: pointer;
							}
						}

						margin-bottom: 5px;
					}

					.title {
						font-size: 13px;
						margin-bottom: 6px;
						display: block;
						font-weight: bold;
					}
				}

				.descr {
					color: lighten(#606060, 20%);
					font-size: 16px;
					margin-top: 5px;

					a {
						color: #3faff1;
					}
				}
			}

			.channel-icon {
				padding: 30px 30px;
				padding-top: 0px;
				display: flex;

				img {
					height: 48px;
				}
			}

			.status-label {
				margin-left: auto;
				border-radius: 6px;
				width: 86px;
				height: 24px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				text-transform: uppercase;
				background: #d7d7d7;
				color: #938f8f;
				font-size: 14px;
				margin-right: 20px;

				&.active {
					color: #0e7418;
					background: #cdfdcc;
				}

				&.error {
					background: #fdcccc;
					color: #d44949;
				}
			}
		}

		&:last-child {
			border: none;
		}
	}
}
</style>
